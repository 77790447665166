import React from "react";
import "./faqsection.css";
import StringData from "../../String.json";

const FAQSection = () => {
  return (
    <>
      <div className="container-fluid faqsection">
        <div className="container">
          <div className="row faqsection-content d-flex flex-wrap">
            <div className="col-md-6 faq-image">
                <img src={StringData.FAQsection.faq_image} alt="faq" />
            </div>
            <div className="col-md-5">
              <div className="faq-header-content mb-5">
                <div className="title text-start">
                  <h2 className="fw-bold">{StringData.FAQsection.title}</h2>
                </div>
                <div className="para text-start">
                  <p className="">
                    Whether you're curious about our services, products, or
                    order, we've got you covered. Explore our FAQs for quick and
                    helpful solutions to common inquiries.
                  </p>
                </div>
              </div>
              <div className="faq-accordion">
                {StringData.FAQsection.faqdata.map((items) => (
                  <div key={items.faqId} className="accordion mb-2" id={`accordionFlushExample-${items.faqId}`}>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${items.faqId}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse-${items.faqId}`}
                        >
                          {items.question}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse-${items.faqId}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading-${items.faqId}`}
                        data-bs-parent={`flush-heading-${items.faqId}`}
                      >
                        <div className="accordion-body text-start">
                          {items.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQSection;
