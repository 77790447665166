import React, { useState, useEffect } from "react";
import "./productsection.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import StringData from "../../String.json";

const ProductSection = () => {
  const [data, setData] = useState(StringData.productsection.productdata);

  useEffect(() => {
    if (
      StringData &&
      StringData.productsection &&
      StringData.productsection.productdata
    ) {
      setData(StringData.productsection.productdata);
    }
  }, [data]);

  return (
    <>
      <div className="container-fluid product-main">
        <div className="container">
          <div className="top-icon">
            <div className="hr-line"></div>
            <div className="circle">
              <img src={StringData.productsection.icon} alt="seeds" />
            </div>
            <div className="hr-line"></div>
          </div>
          <div className="product-content">
            <div className="product-text">
              <h1>Our New Products</h1>
              <p>{StringData.productsection.description}</p>
            </div>

            <Splide
              className="row product-card mt-5 gap-2"
              options={{
                perPage: 4,
                rewind: true,
                gap: "1rem",
                breakpoints: {
                  1024: {
                    perPage: 3,
                    rewind: true,
                    gap: "2rem",
                  },
                  768: {
                    perPage: 2,
                    rewind: true,
                    gap: "2rem",
                  },
                  480: {
                    perPage: 1,
                    rewind: true,
                    gap: "2rem",
                  },
                },
              }}
            >
              {data.map((items) => (
                <SplideSlide
                  key={items.seedID}
                  className="card col-lg-3 col-md-4 col-sm-6 p-2 pb-2"
                  style={{ height: "auto" }}
                >
                  <img
                    src={items.seedimage}
                    className="card-img-top object-fit-cover"
                    alt={items.seedname}
                    style={{ height: "250px" }}
                  />
                  <div
                    className="card-body text-start"
                    style={{ height: "auto" }}
                  >
                    <h5 className="card-title fw-bold">{items.seedname}</h5>
                    <i className="text-secondary">{items.scientific_name}</i>
                    <p className="card-text mt-2">{items.seed_description}</p>
                    <p className="card-text"><strong>Uses:</strong> {items.uses}</p>

                    {/* <p className="card-text">{items.seedprice}</p> */}
                    {/* <a
                      href="https://www.muktinathkrishi.com/shop"
                      className="btn btn-success"
                    >
                      Add to Cart
                    </a> */}
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection;
